<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="text-h6 font-weight-medium ml-3">Toplu SMS Oluştur</div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>
    <form>
      <validation-observer ref="observer">
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Başlık" rules="required|max:500">
          <v-text-field
            v-model="data.subject"
            filled
            :dark="isDark"
            label="Başlık"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="İçerik" rules="required|max:500">
          <v-textarea
            v-model="data.body"
            filled
            :dark="isDark"
            label="İçerik"
            counter
            :error-messages="errors"
            class="rounded-0"
          ></v-textarea>
        </validation-provider>
      </validation-observer>
    </form>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn x-large rounded block :loading="loading" color="secondary" @click="submit"> Gönder </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiEye, mdiEyeOff, mdiPencil, mdiPlusThick } from '@mdi/js'
import { postData } from '@utils'
import Vue from 'vue'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
    userItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isDark } = useAppConfig()

    return {
      isDark,

      icons: {
        mdiClose,
        mdiEyeOff,
        mdiEye,
        mdiPlusThick,
        mdiPencil,
      },
    }
  },
  data: () => ({
    items: [],
    isLoading: false,
    search: null,
    show: false,
    loading: false,
    data: {
      method: '',
      subject: '',
      body: '',
    },
    defaultItem: {
      method: '',
      subject: '',
      body: '',
    },
  }),

  watch: {
    isAddNewUserSidebarActive(val) {
      val || this.close()
    },
  },
  methods: {
    async submit() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.loading = false

        Vue.swal({
          title: 'Hata',
          text: 'Lütfen kırmızı alanları doldurun',
          icon: 'error',
          timer: 2000,
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })

        return
      }

      postData({
        method: 'smsPostToMembers',
        body: this.data.body,
        subject: this.data.subject,
      })
        .then(data => {
          if (data.error == 0) {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)

            Vue.swal({
              title: 'Gönderildi',
              text: 'İşlem başarılı',
              icon: 'error',
              timer: 2000,
              background: '#56CA00',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            Vue.swal({
              title: 'Hata',
              text: data.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.data = { ...this.defaultItem }
      })
    },
  },
}
</script>
